.browse-hotels-page h1 {
  margin-bottom: 24px;
}
.browse-hotels-page .search-input {
  margin-bottom: 24px;
}
.browse-hotels-page .ant-card-cover img {
  height: 200px;
  object-fit: cover;
}
