.scrollable-content {
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.top-content {
  background: #003580;
  height: 84px;
}
.unrestricted-layout {
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  bottom: 0;
  display: flex;
  flex-direction: column;
}
.unrestricted-layout .top-content {
  background: #003580;
  height: 84px;
}
.unrestricted-layout .site-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.unrestricted-layout .scrollable-content {
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 715px;
}
.unrestricted-layout .site-footer {
  text-align: center;
  padding: 24px;
  background: #fff;
}
html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}
