.booking-header {
  padding: 0;
  height: auto;
  color: green;
  top: auto;
}
.booking-header .header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
}
.booking-header .top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  padding: 16px 24px;
  flex-wrap: wrap;
}
.booking-header .top-bar .logo {
  font-size: 24px;
  font-weight: bold;
  color: gold;
  cursor: pointer;
  margin-right: 24px;
  flex: 0 0 auto;
  justify-content: center;
}
.booking-header .top-bar .header-actions {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}
.booking-header .top-bar .header-actions .action-items {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  color: whitesmoke;
  font-size: 18px;
  margin-right: 16px;
}
.booking-header .top-bar .header-actions .action-items .ant-btn {
  margin-top: 9px;
}
.booking-header .nav-bar {
  border-top: 1px solid grey;
  overflow-x: auto;
  padding: 16px;
}
.booking-header .nav-bar .ant-tabs .ant-tabs-nav {
  margin-bottom: 0;
}
.booking-header .nav-bar .ant-tabs .ant-tabs-nav::before {
  border: none;
}
.booking-header .nav-bar .ant-tabs .ant-tabs-nav .ant-tabs-tab {
  padding: 12px 8px;
  margin: 0;
  white-space: nowrap;
  font-size: 18px;
}
.booking-header .nav-bar .nav-item {
  display: flex;
  align-items: center;
  color: whitesmoke;
  margin-right: 24px;
}
.booking-header .nav-bar .nav-item .anticon {
  margin-right: 8px;
}
.booking-header .nav-bar .nav-item.active {
  color: bisque;
}
.booking-header .mobile-menu-button {
  display: none;
}
.booking-header .mobile-menu-drawer .ant-drawer-body {
  padding: 0;
}
.booking-header .mobile-menu-drawer .ant-menu {
  border-right: none;
}
.booking-header .mobile-menu-drawer .mobile-menu-actions {
  padding: 16px;
  font-size: 18px;
}
.booking-header .mobile-menu-drawer .mobile-menu-actions .ant-btn,
.booking-header .mobile-menu-drawer .mobile-menu-actions .ant-select {
  margin-bottom: 16px;
}
@media (max-width: 768px) {
  .booking-header .top-bar {
    padding: 16px;
  }
  .booking-header .top-bar .logo {
    margin-right: 0;
  }
  .booking-header .top-bar .header-actions .desktop-actions {
    display: none;
  }
  .booking-header .desktop-nav {
    display: none;
  }
  .booking-header .mobile-menu-button {
    display: block;
    position: absolute;
    top: 16px;
    left: 16px;
  }
}
@media (min-width: 769px) {
  .booking-header {
    background-color: #00092C;
  }
  .mobile-menu-drawer {
    display: none;
  }
}
