.home-content .hero-section {
  text-align: center;
  margin-bottom: auto;
}
.home-content .hero-section .subtitle {
  font-size: 18px;
}
.home-content .search-box {
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 24px;
}
.home-content .search-box .search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
@media (max-width: 768px) {
  .home-content .search-box .search-form {
    flex-direction: column;
  }
}
.home-content .search-box .search-form > * {
  flex: 1;
  min-width: 200px;
}
@media (max-width: 768px) {
  .home-content .search-box .search-form > * {
    width: 100%;
  }
}
.home-content .search-box .search-form .ant-btn {
  min-width: 120px;
}
.home-content .search-box .toggle-checkbox {
  margin-top: 16px;
}
.home-content .content-section {
  margin-top: 48px;
}
.home-content .content-section .section-title {
  margin-bottom: 24px;
}
.home-content .content-section .destination-card,
.home-content .content-section .deal-card {
  height: 100%;
}
.home-content .content-section .destination-card .ant-card-cover img,
.home-content .content-section .deal-card .ant-card-cover img {
  height: 200px;
  object-fit: cover;
}
.home-content .content-section .destination-card .ant-card-meta-title,
.home-content .content-section .deal-card .ant-card-meta-title {
  font-size: 18px;
  margin-bottom: 8px;
}
.home-content .content-section .destination-card .destination-rating,
.home-content .content-section .deal-card .destination-rating,
.home-content .content-section .destination-card .deal-rating,
.home-content .content-section .deal-card .deal-rating {
  margin-top: 8px;
}
.home-content .content-section .destination-card .deal-price,
.home-content .content-section .deal-card .deal-price {
  font-weight: bold;
}
